import React from "react"
import PropTypes from "prop-types"
import { ReactComponent as GooglePlayDownloadBadgeSvg } from "assets/vendor/googlePlayDownloadBadge.svg"

const StoreDownloadBadgesGooglePlay = (props) => (
  <a
    href={props.href}
    className={props.className}
    aria-label="Get it on Google Play"
    target="_blank"
    rel="noopener noreferrer">
    <GooglePlayDownloadBadgeSvg className="w-auto h-8" />
  </a>
)

StoreDownloadBadgesGooglePlay.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
}

export default StoreDownloadBadgesGooglePlay

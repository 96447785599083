import React from "react"
import PropTypes from "prop-types"
import { ReactComponent as AppStoreDownloadBadgeSvg } from "assets/vendor/appStoreDownloadBadge.svg"

const StoreDownloadBadgesAppStore = (props) => (
  <a
    href={props.href}
    className={props.className}
    aria-label="Download on the App Store"
    target="_blank"
    rel="noopener noreferrer">
    <AppStoreDownloadBadgeSvg className="w-auto h-8" />
  </a>
)

StoreDownloadBadgesAppStore.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
}

export default StoreDownloadBadgesAppStore
